



















































































.link-preview {
  width: 12px;
  height: 16px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 16px;
    left: 5px;
    top: 0;
    background-color: black;
  }

  &.dependency::after {
    background-color: red;
  }
}
